import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

const LegalNotice = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout siteTitle={siteTitle} location={location}>
      <div>
        <h2>According to §5 TMG</h2>
        <p>
          <span>Daniel Klitzke</span>
          <br />
          <span>Zaehringerstrasse 24</span>
          <br />
          <span>76131 Karlsruhe</span>
          <br />
          <span>Germany</span>
          <br />
          <span>info@handsonml.com</span>
        </p>
        <h2>Online Dispute Resolution website of the EU Commission</h2>
        <p>
          In order for consumers and traders to resolve a dispute out-of-court,
          the European Commission developed the Online Dispute Resolution
          Website: www.ec.europa.eu/consumers/odr
        </p>
        <h2>Legal disclaimer</h2>
        <p>
          The contents of these pages were prepared with utmost care.
          Nonetheless, we cannot assume liability for the timeless accuracy and
          completeness of the information.
        </p>
        <p>
          Our website contains links to external websites. As the contents of
          these third-party websites are beyond our control, we cannot accept
          liability for them. Responsibility for the contents of the linked
          pages is always held by the provider or operator of the pages.
        </p>

        <h2>Data protection</h2>
        <p>
          In general, when visiting the website of Daniel Klitzke, no personal
          data are saved. However, these data can be given on a voluntary basis.
          No data will be passed on to third parties without your consent. We
          point out that in regard to unsecured data transmission in the
          internet (e.g. via email), security cannot be guaranteed. Such data
          could possibIy be accessed by third parties.
        </p>

        <h2>Google Analytics</h2>
        <p>
          This website uses Google Analytics, a web analytics service provided
          by Google, Inc. (»Google«). Google Analytics uses cookies, which are
          text files placed on your computer, to help the website analyze how
          users use the site. The information generated by the cookie about your
          use of the website (including your IP address) will be transmitted to
          and stored by Google on servers in the United States. In case of
          activation of the IP anonymization, Google will truncate/anonymize the
          last octet of the IP address for Member States of the European Union
          as well as for other parties to the Agreement on the European Economic
          Area. Only in exceptional cases, the full IP address is sent to and
          shortened by Google servers in the USA. On behalf of the website
          provider Google will use this information for the purpose of
          evaluating your use of the website, compiling reports on website
          activity for website operators and providing other services relating
          to website activity and internet usage to the website provider. Google
          will not associate your IP address with any other data held by Google.
          By using this website, you consent to the processing of data about you
          by Google in the manner and for the purposes set out above. How to
          prevent being tracked by Google Analytics You may refuse the use of
          cookies by selecting the appropriate settings on your browser.
          However, please note that if you do this, you may not be able to use
          the full functionality of this website. Furthermore you can prevent
          Google’s collection and use of data (cookies and IP address) by
          downloading and installing the browser plug-in available under
          https://tools.google.com/dlpage/gaoptout?hl=en.
        </p>
        <p>
          Source:{" "}
          <a href="https://language-boutique.de/naithani/blogpost/impressum-auf-englisch.html">
            Language Boutique.de/Muster-Impressum
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default LegalNotice

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
